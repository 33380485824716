import {
  Client,
  User,
  Annuity,
  Snapshot,
  AnnuityConversionInputs,
} from "@/models";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export class Question {
  constructor(
    public component: string,
    public has_back: boolean = true,
    public question_prompt: string = "not_implemented",
    public secondary_validation: Function = function (
      quiz_state: typeof store.state.quiz_state
    ) {
      return true;
    }
  ) {}
}

const store = new Vuex.Store({
  state: {
    cart: {
      cart_item: null as string | null,
      cart_base: null as string | null,
      cart_usage: null as string | null,
      plan: null as string | null,
      been_to_pricing: null as boolean | null,
    },
    snackbar: {
      show: false,
      text: "",
    },
    user_loading: null as Promise<any> | null,
    _clients_loading: true,
    _clients: [] as Client[],
    _annuities_loading: true,
    _annuities: [] as Annuity[],
    _snapshots_loading: true,
    _snapshots: [] as Snapshot[],
    _morningstar_loading: true,
    _morningstar: [] as any[],
    user: {
      is_login: false,
      acl: 0,
      uid: "",
      first_name: "",
      last_name: "",
      email: "",
    } as User,
    new_annuity_quiz: [
      new Question("ClientQuestion", false),
      new Question("CurrentAnnuityQuestion"),
      new Question("FeesAndChargesQuestion"),
      new Question("KeyDatesQuestion"),
      new Question("CurrentAnnuityDataQuestion"),
      // new Question("SnapshotQuestion"),
      new Question("NewAnnuityQuizComplete"),
    ],
    client_update_annuity_quiz: [] as Question[],
    add_another_annuity_quiz: [] as Question[],
    quiz_meta: {
      current_question: 0,
      current_quiz: "new_annuity_quiz",
    },
    conversion_state: {
      account_value: 0 as number | null,
      free_withdrawal_amount: 0 as number | null,
      surrender_value: 0 as number | null,
      bonus_percentage: 0 as number | null,
      wd_type: "" as string | null,
      has_income_account: false,
      income_account_value: null as number | null,
      has_death_benefit: false,
      death_benefit_amount: null as number | null,
    } as AnnuityConversionInputs,
    quiz_state: {
      // Page 1 - Client Questions
      first_name: "",
      last_name: "",
      dob: "",
      email: "",
      cid: "",
      phone: "",
      state: "",
      notes: "",

      _dob_radio: "dob",
      _dob_day: "",
      _dob_month: "",
      _dob_year: "",
      _select_or_create: "select",

      // Page 2 - Carrier & Product Data
      carrier: "",
      product: "",
      annuity_type: "Non-Qualified",
      asset_type: "va",

      // Page 3 - Fees & Charges
      guaranteed_income: false,
      me_fee: "",
      admin_fee: "",
      expense_ratio: "",
      income_rider_fee: "",
      other_fee: "",

      // Page 4 - Key Dates
      statement_date: "",
      _statement_day: "",
      _statement_month: "",
      _statement_year: "",
      issue_date: "",
      _issue_day: "",
      _issue_month: "",
      _issue_year: "",
      surrender_years: "",

      // Page 5 - Deposits & Withdrawals
      initial_deposit: null as number | null,
      total_additional_deposits: 0 as number | null,
      total_withdrawals: 0 as number | null,

      // Page 6 - Account Values
      current_value: null as number | null,
      mva: false,
      surrender_value: null as number | null,

      // new-statement
      additional_deposits: null as number | null,
      withdrawals: null as number | null,
    },
  },
  getters: {
    clients: (state) => state._clients,
    annuities: (state) => state._annuities,
    snapshots: (state) => state._snapshots,
    morningstar: (state) => state._morningstar,
    morningstar_va: (state) =>
      state._morningstar.filter((x) => x.annuity_type == "va"),
    morningstar_fia: (state) =>
      state._morningstar.filter((x) => x.annuity_type == "fia"),
    morningstar_fia_carriers: (state) =>
      state._morningstar
        .filter((x) => x.annuity_type == "fia")
        .map((x) => x.company_name)
        .sort(),
    morningstar_va_carriers: (state) =>
      state._morningstar
        .filter((x) => x.annuity_type == "va")
        .map((x) => x.company_name)
        .sort(),
    contract_loading: (state) =>
      state._clients_loading ||
      state._annuities_loading ||
      state._snapshots_loading,
  },
  mutations: {
    set_clients(state, payload) {
      state._clients_loading = true;
      state._clients = payload;
      state._clients_loading = false;
    },
    set_annuities(state, payload) {
      state._annuities_loading = true;
      state._annuities = payload;
      state._annuities_loading = false;
    },
    set_snapshots(state, payload) {
      state._snapshots_loading = true;
      state._snapshots = payload;
      state._snapshots_loading = false;
    },
    set_morningstar(state, payload) {
      state._morningstar_loading = true;
      state._morningstar = payload;
      state._morningstar_loading = false;
    },
    initQuiz(state, payload) {
      state.quiz_meta.current_quiz = payload;
      state.quiz_meta.current_question = 0;
      state.quiz_state = {
        // Page 1 - Client Questions
        first_name: "",
        last_name: "",
        dob: "",
        email: "",
        cid: "",
        phone: "",
        state: "",
        notes: "",

        _dob_radio: "dob",
        _dob_day: "",
        _dob_month: "",
        _dob_year: "",
        _select_or_create: "select",

        // Page 2 - Carrier & Product Data
        carrier: "",
        product: "",
        annuity_type: "Non-Qualified",
        asset_type: "va",

        // Page 3 - Fees & Charges
        guaranteed_income: false,
        me_fee: "",
        admin_fee: "",
        expense_ratio: "",
        income_rider_fee: "",
        other_fee: "",

        // Page 4 - Key Dates
        statement_date: "",
        _statement_day: "",
        _statement_month: "",
        _statement_year: "",
        issue_date: "",
        _issue_day: "",
        _issue_month: "",
        _issue_year: "",
        surrender_years: "",

        // Page 5 -Deposits & Withdrawals
        initial_deposit: null as number | null,
        total_additional_deposits: 0 as number | null,
        total_withdrawals: 0 as number | null,

        // Page 6 - Account Values
        current_value: null as number | null,
        mva: false,
        surrender_value: null as number | null,

        // new-statement
        additional_deposits: null as number | null,
        withdrawals: null as number | null,
      };
    },
    set_snackbar(state, payload) {
      state.snackbar.show = true;
      state.snackbar.text = payload;
    },

    updateUser(state, payload) {
      state.user = {
        ...state.user,
        ...payload,
      };
    },

    setUser(state, user: User) {
      if (user === null) {
        state.user = {
          is_login: false,
          acl: 0,
          uid: "",
          first_name: "",
          last_name: "",
          email: "",
          subscription_id: "",
        };
      } else {
        state.user = user;
        state.user.is_login = true;
      }
    },
    nextQuestion(state) {
      if (
        state.quiz_meta.current_question <
        (state as any)[state.quiz_meta.current_quiz].length - 1
      ) {
        state.quiz_meta.current_question++;
      }
    },
    previousQuestion(state) {
      if (state.quiz_meta.current_question > 0) {
        state.quiz_meta.current_question--;
      }
    },
  },
  actions: {},
  modules: {},
});

export default store;
