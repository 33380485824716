
import AOS from "aos";
import "aos/dist/aos.css";
import Vue from "vue";

export default Vue.extend({
  mounted() {
    AOS.init({
      disable: "mobile",
    });
  },
  methods: {
    loggie() {
      console.log(this.$store.state.cart.been_to_pricing);
    },
    goTo(url: string) {
      this.$router.push(url);
    },
  },
});
