import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { db, storage } from "@/firebaseConfig";
import { Client, Annuity, Snapshot } from "@/models";
import { ref, getDownloadURL } from "firebase/storage";

export function watcher(store: any) {
  let cq = query(
    collection(db, "clients"),
    where("uid", "==", store.state.user.uid)
  );
  let unsubClients = onSnapshot(cq, (querySnapshot) => {
    const clients = [] as Client[];
    querySnapshot.forEach((doc) => {
      clients.push({
        id: doc.id,
        ...doc.data(),
      } as Client);
    });
    store.commit("set_clients", clients);
  });

  let aq = query(
    collection(db, "annuities"),
    where("uid", "==", store.state.user.uid)
  );

  let unsubAnnuities = onSnapshot(aq, (querySnapshot) => {
    const annuities = [] as Annuity[];
    querySnapshot.forEach((doc) => {
      annuities.push({
        id: doc.id,
        ...doc.data(),
      } as Annuity);
    });
    store.commit("set_annuities", annuities);
  });

  let sq = query(
    collection(db, "snapshots"),
    where("uid", "==", store.state.user.uid)
  );

  let unsubSnapshot = onSnapshot(sq, (querySnapshot) => {
    const snapshots = [] as Snapshot[];
    querySnapshot.forEach((doc) => {
      snapshots.push({
        id: doc.id,
        ...doc.data(),
      } as Snapshot);
    });
    store.commit("set_snapshots", snapshots);
  });

  if (localStorage.getItem("morningstar") !== null) {
    const ms = JSON.parse(localStorage.getItem("morningstar") || "{}");
    if (ms.timestamp > Date.now() - 604800000) {
      store.commit("set_morningstar", ms.data);
    }
  }

  if (store.state._morningstar.length === 0) {
    getDownloadURL(ref(storage, "main.json")).then((url) => {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem(
            "morningstar",
            JSON.stringify({ timestamp: Date.now(), data: data })
          );
          store.commit("set_morningstar", data);
        });
    });
  }

  return () => {
    unsubClients();
    unsubAnnuities();
    unsubSnapshot();
  };
}
