import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { watcher } from "./plugins/fbContractWatcher";
import { auth, db } from "./firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDoc, doc } from "firebase/firestore";
import vuetify from "./plugins/vuetify";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import "./plugins/vuetify-money.js";
import "./plugins/vuetify-mask.js";

import GTextField from "./components/GTextField.vue";

// if not localhost
if (document.location.hostname !== "localhost") {
  Sentry.init({
    Vue,
    dsn: "https://1d5a3130ec29492b911fd863b2c7f251@o383151.ingest.sentry.io/4504409400016896",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "annuityscore.io", /^\//],
      }),
    ],
    logErrors: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
store.state.user_loading = new Promise((resolve, reject) => {
  onAuthStateChanged(auth, (userFirebase) => {
    if (userFirebase) {
      // this just sets the login state to true
      // so that the navbar can show the correct links
      store.commit("setUser", {
        is_login: true,
        acl: 0,
        uid: "",
        first_name: "",
        last_name: "",
        email: "",
      });

      // this gets the user data from the database
      console.log("user logged in", userFirebase);

      // get user data from db
      const docRef = doc(db, "users", userFirebase.uid);
      try {
        getDoc(docRef).then((doc) => {
          if (doc.exists()) {
            userFirebase.getIdTokenResult().then((idTokenResult) => {
              store.commit("setUser", {
                ...doc.data(),
                acl: idTokenResult.claims.acl || 0,
              });
              watcher(store);
              resolve(null);
            });
          } else {
            new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
              // wait 2 seconds then try again
              getDoc(docRef).then((doc) => {
                if (doc.exists()) {
                  userFirebase.getIdTokenResult().then((idTokenResult) => {
                    store.commit("setUser", {
                      ...doc.data(),
                      acl: idTokenResult.claims.acl || 0,
                    });
                    watcher(store);
                    resolve(null);
                  });
                } else {
                  window.location.href = "/login";
                  resolve(null);
                }
              });
            });

            console.log("No such document!");
            resolve(null);
          }
        });
      } catch (e) {
        new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
          getDoc(docRef).then((doc) => {
            if (doc.exists()) {
              userFirebase.getIdTokenResult().then((idTokenResult) => {
                store.commit("setUser", {
                  ...doc.data(),
                  acl: idTokenResult.claims.acl || 0,
                });
                watcher(store);
                resolve(null);
              });
            } else {
              new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
                // wait 2 seconds then try again
                getDoc(docRef).then((doc) => {
                  if (doc.exists()) {
                    userFirebase.getIdTokenResult().then((idTokenResult) => {
                      store.commit("setUser", {
                        ...doc.data(),
                        acl: idTokenResult.claims.acl || 0,
                      });
                      watcher(store);
                      resolve(null);
                    });
                  } else {
                    window.location.href = "/login";
                    resolve(null);
                  }
                });
              });

              console.log("No such document!");
              resolve(null);
            }
          });
        });
      }
    } else {
      store.commit("setUser", null);
      resolve(null);
    }
  });
});
Vue.config.productionTip = false;
Vue.component("g-text-field", GTextField);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
