
import Vue from "vue";
import { auth } from "@/firebaseConfig";
import { signOut } from "firebase/auth";
export default Vue.extend({
  name: "App",
  created() {},
  mounted() {
    this.resizeOnUserStateChange();

    if (document.location.pathname != "/") {
      this.bg = this.bg_theme;
    } else {
      window.onscroll = () => {
        this.changeColor();
      };
    }
  },
  methods: {
    changeColor() {
      if (document.location.pathname != "/") {
        this.bg = this.bg_theme;
      } else {
        if (
          // document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 120
          // $(window).scrollTop() !== 0
        ) {
          this.bg = this.bg_theme; //bg_theme
        } else {
          this.bg = "transparent";
        }
      }
    },
    async resizeOnUserStateChange() {
      await this.$store.state.user_loading;
      window.dispatchEvent(new Event("resize"));
    },
    signout() {
      signOut(auth)
        .then(() => {
          window.location.href = "/";
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {},
  data() {
    return {
      bg_theme: this.$vuetify.theme.currentTheme.background as string,
      bg: "transparent",
      tab: null,
      drawer: false,
      group: null,
      admin: [
        {
          text: "Advisors",
          icon: "mdi-account",
          to: "/admin/agents",
        },
        {
          text: "Annuities",
          icon: "mdi-calendar",
          to: "/admin/annuities",
        },
      ] as any[],
      loggedOutMenu: [
        {
          text: "Home",
          icon: "mdi-home",
          to: "/",
        },
        {
          text: "Pricing",
          icon: "mdi-storefront",
          to: "/pricing",
        },
        {
          text: "Sign In",
          icon: "mdi-login",
          to: "/login",
        },
        {
          text: "Sign Up",
          icon: "mdi-account-plus-outline",
          to: "/register",
        },
      ] as any[],
      loggedInMenu: [
        {
          text: "Home",
          icon: "mdi-home",
          to: "/",
        },
        {
          text: "Dashboard",
          icon: "mdi-calculator",
          to: "/dashboard",
        },
        {
          text: "Account",
          icon: "mdi-account",
          to: "/account",
        },
        {
          text: "Sign Out",
          icon: "mdi-logout",
          to: "/",
          custom_action: (this as any).signout,
        },
      ] as any[],
    };
  },
});
